// FETCH ALL ORDER LIST
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_ERROR = "FETCH_ORDER_LIST_ERROR";

// FETCH  ORDER
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_ERROR = "FETCH_ORDER_ERROR";

//PAGE_CHANGE Actions
export const ORDER_PAGE_CHANGE = "ORDER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const ORDER_LIMIT_CHANGE = "ORDER_LIMIT_CHANGE"

//edit ORDER
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR";