import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    applicationlist: { filter_record: 0, total_record: 0 },
    page: 1,
    limit: 10,
}

export const ApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_APPLICATION_LIST_SUCCESS:
            return {
                ...state,
                applicationlist: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_APPLICATION_LIST_ERROR:
            return {
                ...state,
                user: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.APPLICATION_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.APPLICATION_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        default:
            return state
    }
}

