//FETCH_LIST Actions
export const FETCHVENDOR_LIST_SUCCESS = "FETCHVENDOR_LIST_SUCCESS";
export const FETCHVENDOR_LIST_ERROR = "FETCHVENDOR_LIST_ERROR";
//PAGE_CHANGE Actions
export const VENDOR_PAGE_CHANGE = "VENDOR_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const VENDOR_LIMIT_CHANGE = "VENDOR_LIMIT_CHANGE"
//vendor category list
export const VENDORCATEGORY_LIST_SUCCESS = "VENDORCATEGORY_LIST_SUCCESS";
export const VENDORCATEGORY_LIST_ERROR = "VENDORCATEGORY_LIST_ERROR";
export const VENDORCATEGORY__PAGE_CHANGE = "VENDORCATEGORY__PAGE_CHANGE"
export const VENDORCATEGORY__LIMIT_CHANGE = "VENDORCATEGORY__LIMIT_CHANGE"
//category add
export const VENDORCATEGORY_ADD_SUCCESS = "VENDORCATEGORY_ADD_SUCCESS";
export const VENDORCATEGORY_ADD_ERROR = "VENDORCATEGORY_ADD_ERROR";
//DEL_categoryActions
export const DEL_VENDORCATEGORY_SUCCESS = "DEL_VENDORCATEGORY_SUCCESS";
export const DEL_VENDORCATEGORY_ERROR = "DEL_VENDORCATEGORY_ERROR";
//FETCH_category Actions
export const FETCHCATEGORYVENDOR_INFO_SUCCESS = "FETCHCATEGORYVENDOR_INFO_SUCCESS";
export const FETCHCATEGORYVENDOR_INFO_ERROR = "FETCHCATEGORYVENDOR_INFO_ERROR";
//EDIT_CATEGORY Actions
export const EDIT_VENDORCATEGORY_SUCCESS = "EDIT_VENDORCATEGORY_SUCCESS";
export const EDIT_VENDORCATEGORY_ERROR = "EDIT_VENDORCATEGORY_ERROR";
//get all vendor category
export const GET_VENDOR_CATEGORY_SUCCESS = "GET_VENDOR_CATEGORY_SUCCESS";
export const GET_VENDOR_CATEGORY_ERROR = "GET_VENDOR_CATEGORY_ERROR";

//add vendor
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_ERROR = "ADD_VENDOR_ERROR";
//edit vendor
export const EDIT_VENDOR_SUCCESS = "EDIT_VENDOR_SUCCESS";
export const EDIT_VENDOR_ERROR = "EDIT_VENDOR_ERROR";
//delete vendor
export const DEL_VENDOR_SUCCESS = "DEL_VENDOR_SUCCESS";
export const DEL_VENDOR_ERROR = "DEL_VENDOR_ERROR";
//get particular vendor
export const GETSPECIFIC_VENDOR_SUCCESS = "GETSPECIFIC_VENDOR_SUCCESS";
export const GETSPECIFIC_VENDOR_ERROR = "GETSPECIFIC_VENDOR_ERROR";