// FETCH ALL REPORTED FEED LIST
export const FETCH_REPORTED_FEED_LIST_SUCCESS = "FETCH_REPORTED_FEED_LIST_SUCCESS";
export const FETCH_REPORTED_FEED_LIST_ERROR = "FETCH_REPORTED_FEED_LIST_ERROR";

// FETCH REPORTED FEED
export const FETCH_REPORTED_FEED_SUCCESS = "FETCH_REPORTED_FEED_SUCCESS";
export const FETCH_REPORTED_FEED_ERROR = "FETCH_REPORTED_FEED_ERROR";

//PAGE_CHANGE Actions
export const REPORTED_FEED_PAGE_CHANGE = "REPORTED_FEED_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const REPORTED_FEED_LIMIT_CHANGE = "REPORTED_FEED_LIMIT_CHANGE"

// DELETE FEED
export const DEL_FEED_SUCCESS = "DEL_FEED_SUCCESS"
export const DEL_FEED_ERROR = "DEL_FEED_ERROR"

//get feed category list
export const GET_CATFEEDLIST_SUCCESS = "GET_CATFEEDLIST_SUCCESS"
export const GET_CATFEEDLIST_ERROR = "GET_CATFEEDLIST_ERROR"
export const CATFEED_PAGE_CHANGE = "CATFEED_PAGE_CHANGE"
export const CATFEED_LIMIT_CHANGE = "CATFEED_LIMIT_CHANGE"

//get particular feed category
export const GET_FEEDCAT_SUCCESS = "GET_FEEDCAT_SUCCESS"
export const GET_FEEDCAT_ERROR = "GET_FEEDCAT_ERROR"

//DELETE feed category
export const DEL_FEEDCAT_SUCCESS = "DEL_FEEDCAT_SUCCESS"
export const DEL_FEEDCAT_ERROR = "DEL_FEEDCAT_ERROR"

//add feed category
export const ADD_FEEDCAT_SUCCESS = "ADD_FEEDCAT_SUCCESS"
export const ADD_FEEDCAT_ERROR = "ADD_FEEDCAT_ERROR"

//edit feed category
export const EDIT_FEEDCAT_SUCCESS = "EDIT_FEEDCAT_SUCCESS"
export const EDIT_FEEDCAT_ERROR = "EDIT_FEEDCAT_ERROR"