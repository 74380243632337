import { changeState } from "./common/changeStateReducer"
import { loginReducer } from "./Login/Reducer"
import { adminReducer } from "./adminProfile/Reducer"
import { UserReducer } from "./User/Reducer"
import { AgentReducer } from "./CMS/Reducer"
import { ReportedUserReducer } from "./ReportedUser/Reducer"
import { ReferralReducer } from "./Referral/Reducer"
import { ApplicationReducer } from "./Application/Reducer"
import { FeedReducer } from "./Feed/Reducer"
import { CommentReducer } from "./Comments/Reducer"
import { VendorReducer } from "./Vendor/Reducer"
import { LearningCenterReducer } from "./LearningCenter/Reducer"
import { NewsReducer } from "./NewsManagement/Reducer"
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { ProffesionalReducer } from "./Professional/Reducer"
import { ServicesReducer } from './MarketingHub/Reducer'
import { OrderReducer } from './Order/Reducer'
import { ColorReducer } from "./Color/Reducer"
import { combineReducers } from "redux"
export const rootReducer = combineReducers({
    changeState: changeState,
    toastr: toastrReducer,
    form: formReducer,
    login: loginReducer,
    admin: adminReducer,
    user: UserReducer,
    cms: AgentReducer,
    reported: ReportedUserReducer,
    referral: ReferralReducer,
    application: ApplicationReducer,
    feed: FeedReducer,
    comment: CommentReducer,
    vendor: VendorReducer,
    learning: LearningCenterReducer,
    news: NewsReducer,
    professional: ProffesionalReducer,
    services: ServicesReducer,
    orders: OrderReducer,
    color: ColorReducer
});