import * as ACTION_TYPES from './Types';

const initialState = {
    message: '',
    error: null,
    adminData: {}
}

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ADMIN_SUCCESS:
            return {
                ...state,
                adminData: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ADMIN_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data
            }
        case ACTION_TYPES.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                adminData: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.UPDATE_ADMIN_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data
            }
        default:
            return state;
    }
}