//FETCH_LIST Actions
export const FETCH_COLORLIST_SUCCESS = "FETCH_COLORLIST_SUCCESS";
export const FETCH_COLORLIST_ERROR = "FETCH_COLORLIST_ERROR";
//PAGE_CHANGE Actions
export const COLOR_PAGE_CHANGE = "COLOR_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const COLOR_LIMIT_CHANGE = "COLOR_LIMIT_CHANGE"
//DEL_COLOR Actions
export const DEL_COLOR_SUCCESS = "DEL_COLOR_SUCCESS";
export const DEL_COLOR_ERROR = "DEL_COLOR_ERROR";

//add color
export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS";
export const ADD_COLOR_ERROR = "ADD_COLOR_ERROR";

//get color
export const GET_COLOR_SUCCESS = "GET_COLOR_SUCCESS";
export const GET_COLOR_ERROR = "GET_COLOR_ERROR";
//edit color
export const EDIT_COLOR_SUCCESS = "EDIT_COLOR_SUCCESS";
export const EDIT_COLOR_ERROR = "EDIT_COLOR_ERROR";