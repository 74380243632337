import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    orderlisrt: { filter_record: 0, total_record: 0 },
    order: {},
    page: 1,
    limit: 10,
}

export const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ORDER_LIST_SUCCESS:
            return {
                ...state,
                orderlisrt: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.ORDER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.ORDER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.FETCH_ORDER_LIST_ERROR:
        case ACTION_TYPES.FETCH_ORDER_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }
}

