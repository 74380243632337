import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    learningcenterList: { filter_record: 0, total_record: 0 },
    learning: {},
    page: 1,
    limit: 10,
    nameerror: null,
    namemessage: "",
    allNames: {}
}

export const LearningCenterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_LEARNINGLIST_SUCCESS:
            return {
                ...state,
                learningcenterList: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.LEARNING_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.LEARNING_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.FETCH_LEARNINGLIST_ERROR:
        case ACTION_TYPES.DEL_LEARNING_ERROR:
        case ACTION_TYPES.LEARNING_ACTIVE_STATUS_ERROR:
        case ACTION_TYPES.ADD_ARTICLEL_ERROR:
        case ACTION_TYPES.EDIT_ARTICLEL_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.DEL_LEARNING_SUCCESS:
        case ACTION_TYPES.ADD_ARTICLEL_SUCCESS:
        case ACTION_TYPES.FETCH_ARTICLEL_INFO_SUCCESS:
        case ACTION_TYPES.EDIT_ARTICLEL_SUCCESS:
            return {
                ...state,
                learning: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.LEARNING_ACTIVE_STATUS_SUCCESS:
            return {
                ...state,
                learning: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_ARTICLEL_INFO_ERROR:
            return {
                ...state,
                learning: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.GET_REPORTEDUSERSLEARNING_SUCCESS:
            return {
                ...state,
                allNames: action.payload.data,
                namemessage: action.payload.message,
                nameerror: null
            }
        case ACTION_TYPES.GET_REPORTEDUSERSLEARNING_ERROR:
            return {
                ...state,
                namemessage: action.payload.data.message,
                nameerror: action.payload.data.message
            }
        default:
            return state
    }
}

