import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    catmessage: "",
    caterror: null,
    feedlist: { filter_record: 0, total_record: 0 },
    catfeedlist: { filter_record: 0, total_record: 0 },
    feedInfo: {},
    catinfo: {},
    page: 1,
    limit: 10,
    catpage: 1,
    catlimit: 10
}

export const FeedReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_REPORTED_FEED_LIST_SUCCESS:
            return {
                ...state,
                feedlist: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CATFEEDLIST_SUCCESS:
            return {
                ...state,
                catfeedlist: action.payload,
                catmessage: action.payload.message,
                caterror: null
            }
        case ACTION_TYPES.FETCH_REPORTED_FEED_LIST_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.GET_CATFEEDLIST_ERROR:
        case ACTION_TYPES.ADD_FEEDCAT_ERROR:
        case ACTION_TYPES.DEL_FEEDCAT_ERROR:
        case ACTION_TYPES.EDIT_FEEDCAT_ERROR:
            return {
                ...state,
                catmessage: action.payload.data.message,
                caterror: action.payload.data.message
            }
        case ACTION_TYPES.FETCH_REPORTED_FEED_SUCCESS:
            return {
                ...state,
                feedInfo: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_REPORTED_FEED_ERROR:
            return {
                ...state,
                feedInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message,
            }
        case ACTION_TYPES.REPORTED_FEED_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.REPORTED_FEED_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.CATFEED_PAGE_CHANGE:
            return {
                ...state,
                catpage: action.payload
            }
        case ACTION_TYPES.CATFEED_LIMIT_CHANGE:
            return {
                ...state,
                catlimit: action.payload
            }
        case ACTION_TYPES.DEL_FEED_SUCCESS:
            return {
                ...state,
                feedInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.DEL_FEED_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.ADD_FEEDCAT_SUCCESS:
        case ACTION_TYPES.DEL_FEEDCAT_SUCCESS:
        case ACTION_TYPES.GET_FEEDCAT_SUCCESS:
        case ACTION_TYPES.EDIT_FEEDCAT_SUCCESS:
            return {
                ...state,
                catinfo: action.payload.data,
                catmessage: action.payload.message,
                isEdited: false,
                caterror: null
            }
        case ACTION_TYPES.GET_FEEDCAT_ERROR:
            return {
                ...state,
                catinfo: null,
                catmessage: action.payload.data.message,
                caterror: action.payload.data.message
            }
        default:
            return state
    }
}

