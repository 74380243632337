// FETCH ALL SERVICE LIST
export const FETCH_SERVICE_LIST_SUCCESS = "FETCH_SERVICE_LIST_SUCCESS";
export const FETCH_SERVICE_LIST_ERROR = "FETCH_SERVICE_LIST_ERROR";

// FETCH  SERVICE
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_ERROR = "FETCH_SERVICE_ERROR";

// ADD  SERVICE
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_ERROR = "ADD_SERVICE_ERROR";

//PAGE_CHANGE Actions
export const SERVICE_PAGE_CHANGE = "SERVICE_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const SERVICE_LIMIT_CHANGE = "SERVICE_LIMIT_CHANGE"

//edit service
export const EDIT_SERVICE_SUCCESS = "EDIT_SERVICE_SUCCESS";
export const EDIT_SERVICE_ERROR = "EDIT_SERVICE_ERROR";

// DELETE SERVICE
export const DEL_SERVICE_SUCCESS = "DEL_SERVICE_SUCCESS"
export const DEL_SERVICE_ERROR = "DEL_SERVICE_ERROR"

// FETCH ALL TEMPLATE LIST
export const FETCH_TEMPLATE_LIST_SUCCESS = "FETCH_TEMPLATE_LIST_SUCCESS";
export const FETCH_TEMPLATE_LIST_ERROR = "FETCH_TEMPLATE_LIST_ERROR";

//edit template
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_ERROR = "EDIT_TEMPLATE_ERROR";

// FETCH  TEMPLATE
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_ERROR = "FETCH_TEMPLATE_ERROR";

// ADD  TEMPLATE
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_ERROR = "ADD_TEMPLATE_ERROR";

//PAGE_CHANGE Actions
export const TEMPLATE_PAGE_CHANGE = "TEMPLATE_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const TEMPLATE_LIMIT_CHANGE = "TEMPLATE_LIMIT_CHANGE"

// DELETE TEMPLATE
export const DEL_TEMPLATE_SUCCESS = "DEL_TEMPLATE_SUCCESS"
export const DEL_TEMPLATE_ERROR = "DEL_TEMPLATE_ERROR"