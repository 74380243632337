// FETCH ALL professional category LIST
export const FETCH_PROFCAT_LIST_SUCCESS = "FETCH_PROFCAT_LIST_SUCCESS";
export const FETCH_PROFCAT_LIST_ERROR = "FETCH_PROFCAT_LIST_ERROR";

//PAGE_CHANGE Actions
export const PROFCAT_PAGE_CHANGE = "PROFCAT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const PROFCAT_LIMIT_CHANGE = "PROFCAT_LIMIT_CHANGE"

//category add
export const PROFCATEGORY_ADD_SUCCESS = "PROFCATEGORY_ADD_SUCCESS";
export const PROFCATEGORY_ADD_ERROR = "PROFCATEGORY_ADD_ERROR";
//DEL_categoryActions
export const DEL_PROFCATEGORY_SUCCESS = "DEL_PROFCATEGORY_SUCCESS";
export const DEL_PROFCATEGORY_ERROR = "DEL_PROFCATEGORY_ERROR";
//FETCH_category Actions
export const FETCHCATEGORYPROF_INFO_SUCCESS = "FETCHCATEGORYPROF_INFO_SUCCESS";
export const FETCHCATEGORYPROF_INFO_ERROR = "FETCHCATEGORYPROF_INFO_ERROR";
//EDIT_CATEGORY Actions
export const EDIT_PROFCATEGORY_SUCCESS = "EDIT_PROFCATEGORY_SUCCESS";
export const EDIT_PROFCATEGORY_ERROR = "EDIT_PROFCATEGORY_ERROR";

//get all professional list
export const FETCH_PROF_LIST_SUCCESS = "FETCH_PROF_LIST_SUCCESS";
export const FETCH_PROF_LIST_ERROR = "FETCH_PROF_LIST_ERROR";
//PAGE_CHANGE Actions
export const PROF_PAGE_CHANGE = "PROF_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const PROF_LIMIT_CHANGE = "PROF_LIMIT_CHANGE"
//delete professional
export const DEL_PROF_SUCCESS = "DEL_PROF_SUCCESS";
export const DEL_PROF_ERROR = "DEL_PROF_ERROR";

//get all categorylist in business
export const GET_ALLPROFCAT_SUCCESS = "GET_ALLPROFCAT_SUCCESS";
export const GET_ALLPROFCAT_ERROR = "GET_ALLPROFCAT_ERROR";

//add professional
export const ADD_PROF_SUCCESS = "ADD_PROF_SUCCESS";
export const ADD_PROF_ERROR = "ADD_PROF_ERROR";
//get professional
export const GET_PROF_SUCCESS = "GET_PROF_SUCCESS";
export const GET_PROF_ERROR = "GET_PROF_ERROR";
//edit professional
export const EDIT_PROF_SUCCESS = "EDIT_PROF_SUCCESS";
export const EDIT_PROF_ERROR = "EDIT_PROF_ERROR";

//get all cities listing
export const GET_ALLCITY_SUCCESS = "GET_ALLCITY_SUCCESS";
export const GET_ALLCITY_ERROR = "GET_ALLCITY_ERROR";

//add prof automatically
export const ADD_PROFAUTO_SUCCESS = "ADD_PROFAUTO_SUCCESS";
export const ADD_PROFAUTO_ERROR = "ADD_PROFAUTO_ERROR";