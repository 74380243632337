//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";
//PAGE_CHANGE Actions
export const USER_PAGE_CHANGE = "USER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_LIMIT_CHANGE = "USER_LIMIT_CHANGE"
//DEL_USER Actions
export const DEL_USER_SUCCESS = "DEL_USER_SUCCESS";
export const DEL_USER_ERROR = "DEL_USER_ERROR";
//USER_ACTIVE_STATUS Actions
export const USER_ACTIVE_STATUS_SUCCESS = "USER_ACTIVE_STATUS_SUCCESS";
export const USER_ACTIVE_STATUS_ERROR = "USER_ACTIVE_STATUS_ERROR";
//FETCH_USER Actions
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";
//EDIT_USER Actions
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
//add user ACTIONS
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
//specific user referral details
export const SPECIFICUSER_REFERRAL_SUCCESS = "SPECIFICUSER_REFERRAL_SUCCESS";
export const SPECIFICUSER_REFERRAL_ERROR = "SPECIFICUSER_REFERRAL_ERROR";
export const SPECIFIC_USERREFERRAL_PAGE_CHANGE = "SPECIFIC_USERREFERRAL_PAGE_CHANGE"
export const SPECIFIC_USERREFERRAL_LIMIT_CHANGE = "SPECIFIC_USERREFERRAL_LIMIT_CHANGE"
//specific user application details
export const SPECIFICUSER_APPLICATION_SUCCESS = "SPECIFICUSER_APPLICATION_SUCCESS";
export const SPECIFICUSER_APPLICATION_ERROR = "SPECIFICUSER_APPLICATION_ERROR";
export const SPECIFIC_USERAPPLICATION_PAGE_CHANGE = "SPECIFIC_USERAPPLICATION_PAGE_CHANGE"
export const SPECIFIC_USERAPPLICATION_LIMIT_CHANGE = "SPECIFIC_USERAPPLICATION_LIMIT_CHANGE"
//specific user credit details
export const SPECIFICUSER_CREDIT_SUCCESS = "SPECIFICUSER_CREDIT_SUCCESS";
export const SPECIFICUSER_CREDIT_ERROR = "SPECIFICUSER_CREDIT_ERROR";
export const SPECIFIC_USERCREDIT_PAGE_CHANGE = "SPECIFIC_USERCREDIT_PAGE_CHANGE"
export const SPECIFIC_USERCREDIT_LIMIT_CHANGE = "SPECIFIC_USERCREDIT_LIMIT_CHANGE"
//specific user withdrawal details
export const SPECIFICUSER_WITHDRAWAL_SUCCESS = "SPECIFICUSER_WITHDRAWAL_SUCCESS";
export const SPECIFICUSER_WITHDRAWAL_ERROR = "SPECIFICUSER_WITHDRAWAL_ERROR";
export const SPECIFIC_USERWITHDRAWAL_PAGE_CHANGE = "SPECIFIC_USERWITHDRAWAL_PAGE_CHANGE"
export const SPECIFIC_USERWITHDRAWAL_LIMIT_CHANGE = "SPECIFIC_USERWITHDRAWAL_LIMIT_CHANGE"
//specific user referall joinee details
export const SPECIFICUSER_REFJOINEE_SUCCESS = "SPECIFICUSER_REFJOINEE_SUCCESS";
export const SPECIFICUSER_REFJOINEE_ERROR = "SPECIFICUSER_REFJOINEE_ERROR";
export const SPECIFIC_USERREFJOINEE_PAGE_CHANGE = "SPECIFIC_USERREFJOINEE_PAGE_CHANGE"
export const SPECIFIC_USERREFJOINEE_LIMIT_CHANGE = "SPECIFIC_USERREFJOINEE_LIMIT_CHANGE"