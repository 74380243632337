import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    servicelist: { filter_record: 0, total_record: 0 },
    templatelist: { filter_record: 0, total_record: 0 },
    service: {},
    template: {},
    tempPage: 1,
    tempLimit: 10,
    page: 1,
    limit: 10,
}

export const ServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_SERVICE_LIST_SUCCESS:
            return {
                ...state,
                servicelist: action.payload,
                message: action.payload.message,
                error: null
            }

        case ACTION_TYPES.FETCH_SERVICE_SUCCESS:
            return {
                ...state,
                service: action.payload.data,
                message: action.payload.message,
                error: null
            }


        case ACTION_TYPES.ADD_SERVICE_SUCCESS:
        case ACTION_TYPES.ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.DEL_SERVICE_SUCCESS:
        case ACTION_TYPES.DEL_TEMPLATE_SUCCESS:

            return {
                ...state,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.EDIT_SERVICE_SUCCESS:
        case ACTION_TYPES.EDIT_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.SERVICE_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.SERVICE_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.FETCH_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                templatelist: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_TEMPLATE_SUCCESS:
            return {
                ...state,
                template: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.TEMPLATE_PAGE_CHANGE:
            return {
                ...state,
                tempPage: action.payload
            }
        case ACTION_TYPES.TEMPLATE_LIMIT_CHANGE:
            return {
                ...state,
                tempLimit: action.payload
            }
        case ACTION_TYPES.FETCH_SERVICE_LIST_ERROR:
        case ACTION_TYPES.FETCH_SERVICE_ERROR:
        case ACTION_TYPES.DEL_SERVICE_ERROR:
        case ACTION_TYPES.ADD_SERVICE_ERROR:
        case ACTION_TYPES.EDIT_SERVICE_ERROR:
        case ACTION_TYPES.FETCH_TEMPLATE_LIST_ERROR:
        case ACTION_TYPES.DEL_TEMPLATE_ERROR:
        case ACTION_TYPES.FETCH_TEMPLATE_ERROR:
        case ACTION_TYPES.ADD_TEMPLATE_ERROR:
        case ACTION_TYPES.EDIT_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }
}

