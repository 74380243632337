import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    profmessage: "",
    proferror: null,
    professionalList: { filter_record: 0, total_record: 0 },
    professional: {},
    professionalcategoryList: { filter_record: 0, total_record: 0 },
    professionalcategory: {},
    allcategory: {},
    allcity: {},
    page: 1,
    limit: 10,
    profpage: 1,
    proflimit: 10,
    allcategorymessage: "",
    allcategoryerror: null,
    allcitymessage: "",
    allcityerror: null
}

export const ProffesionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_PROFCAT_LIST_SUCCESS:
            return {
                ...state,
                professionalcategoryList: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_PROF_LIST_SUCCESS:
            return {
                ...state,
                professionalList: action.payload,
                profmessage: action.payload.message,
                proferror: null
            }
        case ACTION_TYPES.GET_ALLPROFCAT_SUCCESS:
            return {
                ...state,
                allcategory: action.payload,
                allcategorymessage: action.payload.message,
                allcategoryerror: null
            }
        case ACTION_TYPES.GET_ALLPROFCAT_ERROR:
            return {
                ...state,
                allcategorymessage: action.payload.data.message,
                allcategoryerror: action.payload.data.message
            }
        case ACTION_TYPES.FETCH_PROFCAT_LIST_ERROR:
        case ACTION_TYPES.PROFCATEGORY_ADD_ERROR:
        case ACTION_TYPES.DEL_PROFCATEGORY_ERROR:
        case ACTION_TYPES.EDIT_PROFCATEGORY_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.FETCH_PROF_LIST_ERROR:
        case ACTION_TYPES.DEL_PROF_ERROR:
        case ACTION_TYPES.ADD_PROF_ERROR:
        case ACTION_TYPES.ADD_PROFAUTO_ERROR:
        case ACTION_TYPES.EDIT_PROF_ERROR:
            return {
                ...state,
                profmessage: action.payload.data.message,
                proferror: action.payload.data.message
            }
        case ACTION_TYPES.PROFCAT_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.PROFCAT_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.PROF_PAGE_CHANGE:
            return {
                ...state,
                profpage: action.payload
            }
        case ACTION_TYPES.PROF_LIMIT_CHANGE:
            return {
                ...state,
                proflimit: action.payload
            }
        case ACTION_TYPES.FETCHCATEGORYPROF_INFO_ERROR:
            return {
                ...state,
                professionalcategory: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.PROFCATEGORY_ADD_SUCCESS:
        case ACTION_TYPES.DEL_PROFCATEGORY_SUCCESS:
        case ACTION_TYPES.EDIT_PROFCATEGORY_SUCCESS:
            return {
                ...state,
                professionalcategory: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.DEL_PROF_SUCCESS:
        case ACTION_TYPES.ADD_PROF_SUCCESS:
        case ACTION_TYPES.ADD_PROFAUTO_SUCCESS:
        case ACTION_TYPES.EDIT_PROF_SUCCESS:
        case ACTION_TYPES.GET_PROF_SUCCESS:
            return {
                ...state,
                professional: action.payload.data,
                profmessage: action.payload.message,
                isEdited: false,
                proferror: null
            }
        case ACTION_TYPES.FETCHCATEGORYPROF_INFO_SUCCESS:
            return {
                ...state,
                professionalcategory: action.payload,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.GET_PROF_ERROR:
            return {
                ...state,
                professional: null,
                profmessage: action.payload.data.message,
                proferror: action.payload.data.message
            }
        case ACTION_TYPES.GET_ALLCITY_SUCCESS:
            return {
                ...state,
                allcity: action.payload.data,
                allcitymessage: action.payload.message,
                allcityerror: null
            }
        case ACTION_TYPES.GET_ALLCITY_ERROR:
            return {
                ...state,
                allcitymessage: action.payload.data.message,
                allcityerror: action.payload.data.message
            }
        default:
            return state
    }
}

