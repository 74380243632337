import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    messagecomment: "",
    errorcomment: null,
    newsList: { filter_record: 0, total_record: 0 },
    newsInfo: {},
    page: 1,
    limit: 10,
    nameerror: null,
    namemessage: "",
    allNames: {},
    commentpage: 1,
    commentlimit: 10,
    commentList: { filter_record: 0, total_record: 0 },
    allNamesComment: {}
}

export const NewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_NEWS_LIST_SUCCESS:
            return {
                ...state,
                newsList: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.NEWS_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.FETCH_NEWS_SUCCESS:
            return {
                ...state,
                newsInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.ADD_NEWS_SUCCESS:
            return {
                ...state,
                newsInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.NEWS_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.NEWS_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.DEL_NEWS_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_NEWS_LIST_ERROR:
        case ACTION_TYPES.DEL_NEWS_ERROR:
        case ACTION_TYPES.ADD_NEWS_ERROR:
        case ACTION_TYPES.FETCH_NEWS_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.GET_REPORTEDUSERS_SUCCESS:
            return {
                ...state,
                allNames: action.payload.data,
                namemessage: action.payload.message,
                nameerror: null
            }
        case ACTION_TYPES.GET_REPORTEDUSERS_ERROR:
            return {
                ...state,
                namemessage: action.payload.data.message,
                nameerror: action.payload.data.message
            }
        case ACTION_TYPES.GET_REPORTEDCOMMENTNEWS_SUCCESS:
            return {
                ...state,
                commentList: action.payload,
                messagecomment: action.payload.message,
                errorcomment: null
            }
        case ACTION_TYPES.GET_REPORTEDCOMMENTNEWS_ERROR:
        case ACTION_TYPES.DEL_REPORTEDCOMMENTNEWS_ERROR:
            return {
                ...state,
                messagecomment: action.payload.data.message,
                errorcomment: action.payload.data.message
            }
        case ACTION_TYPES.REPCOMMENT_PAGE_CHANGE:
            return {
                ...state,
                commentpage: action.payload
            }
        case ACTION_TYPES.REPCOMMENT_LIMIT_CHANGE:
            return {
                ...state,
                commentlimit: action.payload
            }
        case ACTION_TYPES.DEL_REPORTEDCOMMENTNEWS_SUCCESS:
            return {
                ...state,
                messagecomment: action.payload.message,
                errorcomment: null
            }
        case ACTION_TYPES.GET_REPORTEDCOMMENTNEWSUSERS_SUCCESS:
            return {
                ...state,
                allNamesComment: action.payload.data,
                namemessage: action.payload.message,
                nameerror: null
            }
        case ACTION_TYPES.GET_REPORTEDCOMMENTNEWSUSERS_ERROR:
            return {
                ...state,
                namemessage: action.payload.data.message,
                nameerror: action.payload.data.message
            }
        default:
            return state
    }
}

