import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    categorymessage: "",
    categoryerror: null,
    vendorList: { filter_record: 0, total_record: 0 },
    vendor: {},
    categoryList: { filter_record: 0, total_record: 0 },
    category: {},
    getAllCategory: {},
    page: 1,
    limit: 10,
    categorypage: 1,
    categorylimit: 10,
    allcategorymessage: "",
    allcategoryerror: null
}

export const VendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCHVENDOR_LIST_SUCCESS:
            return {
                ...state,
                vendorList: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                getAllCategory: action.payload,
                allcategorymessage: action.payload.message,
                allcategoryerror: null
            }
        case ACTION_TYPES.VENDORCATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
                categorymessage: action.payload.message,
                categoryerror: null
            }
        case ACTION_TYPES.VENDOR_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.VENDORCATEGORY__PAGE_CHANGE:
            return {
                ...state,
                categorypage: action.payload
            }
        case ACTION_TYPES.VENDOR_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.VENDORCATEGORY__LIMIT_CHANGE:
            return {
                ...state,
                categorylimit: action.payload
            }
        case ACTION_TYPES.VENDORCATEGORY_ADD_SUCCESS:
        case ACTION_TYPES.DEL_VENDORCATEGORY_SUCCESS:
        case ACTION_TYPES.FETCHCATEGORYVENDOR_INFO_SUCCESS:
        case ACTION_TYPES.EDIT_VENDORCATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload.data,
                categorymessage: action.payload.message,
                isEdited: false,
                categoryerror: null
            }
        case ACTION_TYPES.ADD_VENDOR_SUCCESS:
        case ACTION_TYPES.EDIT_VENDOR_SUCCESS:
        case ACTION_TYPES.DEL_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.GETSPECIFIC_VENDOR_SUCCESS:
            return {
                ...state,
                vendor: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.FETCHVENDOR_LIST_ERROR:
        case ACTION_TYPES.ADD_VENDOR_ERROR:
        case ACTION_TYPES.EDIT_VENDOR_ERROR:
        case ACTION_TYPES.DEL_VENDOR_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.VENDORCATEGORY_LIST_ERROR:
        case ACTION_TYPES.VENDORCATEGORY_ADD_ERROR:
        case ACTION_TYPES.DEL_VENDORCATEGORY_ERROR:
        case ACTION_TYPES.EDIT_VENDORCATEGORY_ERROR:
            return {
                ...state,
                categorymessage: action.payload.data.message,
                categoryerror: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCHCATEGORYVENDOR_INFO_ERROR:
            return {
                ...state,
                category: null,
                categorymessage: action.payload.data.message,
                categoryerror: action.payload.data.message
            }
        case ACTION_TYPES.GETSPECIFIC_VENDOR_ERROR:
            return {
                ...state,
                vendor: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.GET_VENDOR_CATEGORY_ERROR:
            return {
                ...state,
                allcategorymessage: action.payload.data.message,
                allcategoryerror: action.payload.data.message
            }
        default:
            return state
    }
}

