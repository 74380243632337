import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    referralmessage: "",
    referralerror: null,
    applicationmessage: "",
    applicationerror: null,
    creditmessage: "",
    crediterror: null,
    withdrawalmessage: "",
    withdrawalerror: null,
    joineemessage: "",
    joineeerror: null,
    userList: { filter_record: 0, total_record: 0 },
    userReferralList: { filter_record: 0, total_record: 0 },
    userApplicationList: { filter_record: 0, total_record: 0 },
    userCreditList: { filter_record: 0, total_record: 0 },
    userWithdrawalList: { filter_record: 0, total_record: 0 },
    userJoineeList: { filter_record: 0, total_record: 0 },
    user: {},
    page: 1,
    limit: 10,
    referralpage: 1,
    referrallimit: 10,
    applicationpage: 1,
    applicationlimit: 10,
    creditpage: 1,
    creditlimit: 10,
    withdrawalpage: 1,
    withdrawallimit: 10,
    joineepage: 1,
    joineelimit: 10,
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.SPECIFICUSER_CREDIT_SUCCESS:
            return {
                ...state,
                userCreditList: action.payload,
                creditmessage: action.payload.message,
                crediterror: null
            }
        case ACTION_TYPES.SPECIFICUSER_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                userWithdrawalList: action.payload,
                withdrawalmessage: action.payload.message,
                withdrawalerror: null
            }
        case ACTION_TYPES.SPECIFICUSER_REFJOINEE_SUCCESS:
            return {
                ...state,
                userJoineeList: action.payload,
                joineemessage: action.payload.message,
                joineeerror: null
            }
        case ACTION_TYPES.SPECIFICUSER_REFERRAL_SUCCESS:
            return {
                ...state,
                userReferralList: action.payload,
                referralmessage: action.payload.message,
                referralerror: null
            }
        case ACTION_TYPES.SPECIFICUSER_APPLICATION_SUCCESS:
            return {
                ...state,
                userApplicationList: action.payload,
                applicationmessage: action.payload.message,
                applicationerror: null
            }
        case ACTION_TYPES.USER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERREFERRAL_PAGE_CHANGE:
            return {
                ...state,
                referralpage: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERAPPLICATION_PAGE_CHANGE:
            return {
                ...state,
                applicationpage: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERCREDIT_PAGE_CHANGE:
            return {
                ...state,
                creditpage: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERWITHDRAWAL_PAGE_CHANGE:
            return {
                ...state,
                withdrawalpage: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERREFJOINEE_PAGE_CHANGE:
            return {
                ...state,
                joineepage: action.payload
            }
        case ACTION_TYPES.USER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERREFERRAL_LIMIT_CHANGE:
            return {
                ...state,
                referrallimit: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERAPPLICATION_LIMIT_CHANGE:
            return {
                ...state,
                applicationlimit: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERCREDIT_LIMIT_CHANGE:
            return {
                ...state,
                creditlimit: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERWITHDRAWAL_LIMIT_CHANGE:
            return {
                ...state,
                withdrawallimit: action.payload
            }
        case ACTION_TYPES.SPECIFIC_USERREFJOINEE_LIMIT_CHANGE:
            return {
                ...state,
                joineelimit: action.payload
            }
        case ACTION_TYPES.DEL_USER_SUCCESS:
        case ACTION_TYPES.ADD_USER_SUCCESS:
        case ACTION_TYPES.FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.USER_ACTIVE_STATUS_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.EDIT_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_LIST_ERROR:
        case ACTION_TYPES.DEL_USER_ERROR:
        case ACTION_TYPES.ADD_USER_ERROR:
        case ACTION_TYPES.USER_ACTIVE_STATUS_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_USER_INFO_ERROR:
            return {
                ...state,
                user: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.EDIT_USER_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.SPECIFICUSER_REFERRAL_ERROR:
            return {
                ...state,
                referralmessage: action.payload.data.message,
                referralerror: action.payload.data.message,
            }
        case ACTION_TYPES.SPECIFICUSER_APPLICATION_ERROR:
            return {
                ...state,
                applicationmessage: action.payload.data.message,
                applicationerror: action.payload.data.message,
            }
        case ACTION_TYPES.SPECIFICUSER_CREDIT_ERROR:
            return {
                ...state,
                creditmessage: action.payload.data.message,
                crediterror: action.payload.data.message,
            }
        case ACTION_TYPES.SPECIFICUSER_WITHDRAWAL_ERROR:
            return {
                ...state,
                withdrawalmessage: action.payload.data.message,
                withdrawalerror: action.payload.data.message,
            }
        case ACTION_TYPES.SPECIFICUSER_REFJOINEE_ERROR:
            return {
                ...state,
                joineemessage: action.payload.data.message,
                joineeerror: action.payload.data.message,
            }
        default:
            return state
    }
}

