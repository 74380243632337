// FETCH ALL REFERRAL LIST
export const FETCH_REPORTED_COMMENT_LIST_SUCCESS = "FETCH_REPORTED_COMMENT_LIST_SUCCESS";
export const FETCH_REPORTED_COMMENT_LIST_ERROR = "FETCH_REPORTED_COMMENT_LIST_ERROR";

//PAGE_CHANGE Actions
export const REPORTED_COMMENT_PAGE_CHANGE = "REPORTED_COMMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const REPORTED_COMMENT_LIMIT_CHANGE = "REPORTED_COMMENT_LIMIT_CHANGE"

// DELETE COMMENT
export const DEL_COMMENT_SUCCESS = "DEL_COMMENT_SUCCESS"
export const DEL_COMMENT_ERROR = "DEL_COMMENT_ERROR"