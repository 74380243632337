// FETCH ALL NEWS LIST
export const FETCH_NEWS_LIST_SUCCESS = "FETCH_NEWS_LIST_SUCCESS";
export const FETCH_NEWS_LIST_ERROR = "FETCH_NEWS_LIST_ERROR";

// FETCH  NEWS
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_ERROR = "FETCH_NEWS_ERROR";

//PAGE_CHANGE Actions
export const NEWS_PAGE_CHANGE = "NEWS_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const NEWS_LIMIT_CHANGE = "NEWS_LIMIT_CHANGE"

// change status
export const NEWS_STATUS_CHANGE_SUCCESS = "NEWS_STATUS_CHANGE_SUCCESS"
export const NEWS_STATUS_CHANGE_ERROR = "NEWS_STATUS_CHANGE_ERROR"

// DELETE NEWS
export const DEL_NEWS_SUCCESS = "DEL_NEWS_SUCCESS"
export const DEL_NEWS_ERROR = "DEL_NEWS_ERROR"

// add news 
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS"
export const ADD_NEWS_ERROR = "ADD_NEWS_ERROR"

//get reportedby user details 
export const GET_REPORTEDUSERS_SUCCESS = "GET_REPORTEDUSERS_SUCCESS"
export const GET_REPORTEDUSERS_ERROR = "GET_REPORTEDUSERS_ERROR"

//get reported comment list 
export const GET_REPORTEDCOMMENTNEWS_SUCCESS = "GET_REPORTEDCOMMENTNEWS_SUCCESS"
export const GET_REPORTEDCOMMENTNEWS_ERROR = "GET_REPORTEDCOMMENTNEWS_ERROR"

//PAGE_CHANGE Actions
export const REPCOMMENT_PAGE_CHANGE = "REPCOMMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const REPCOMMENT_LIMIT_CHANGE = "REPCOMMENT_LIMIT_CHANGE"

//DELETE reported comment
export const DEL_REPORTEDCOMMENTNEWS_SUCCESS = "DEL_REPORTEDCOMMENTNEWS_SUCCESS"
export const DEL_REPORTEDCOMMENTNEWS_ERROR = "DEL_REPORTEDCOMMENTNEWS_ERROR"

//USER LIST reported comment
export const GET_REPORTEDCOMMENTNEWSUSERS_SUCCESS = "GET_REPORTEDCOMMENTNEWSUSERS_SUCCESS"
export const GET_REPORTEDCOMMENTNEWSUSERS_ERROR = "GET_REPORTEDCOMMENTNEWSUSERS_ERROR"