import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    commentlist: { filter_record: 0, total_record: 0 },
    page: 1,
    limit: 10,
}

export const CommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_REPORTED_COMMENT_LIST_SUCCESS:
            return {
                ...state,
                commentlist: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_REPORTED_COMMENT_LIST_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.REPORTED_COMMENT_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.REPORTED_COMMENT_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.DEL_COMMENT_SUCCESS:
            return {
                ...state,
                commentlist: action.payload,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.DEL_COMMENT_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }
}

