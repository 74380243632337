//FETCH_LIST Actions
export const FETCH_LEARNINGLIST_SUCCESS = "FETCH_LEARNINGLIST_SUCCESS";
export const FETCH_LEARNINGLIST_ERROR = "FETCH_LEARNINGLIST_ERROR";
//PAGE_CHANGE Actions
export const LEARNING_PAGE_CHANGE = "LEARNING_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const LEARNING_LIMIT_CHANGE = "LEARNING_LIMIT_CHANGE"
//DEL_LEARNING Actions
export const DEL_LEARNING_SUCCESS = "DEL_LEARNING_SUCCESS";
export const DEL_LEARNING_ERROR = "DEL_LEARNING_ERROR";

//LEARNING_ACTIVE_STATUS Actions
export const LEARNING_ACTIVE_STATUS_SUCCESS = "LEARNING_ACTIVE_STATUS_SUCCESS";
export const LEARNING_ACTIVE_STATUS_ERROR = "LEARNING_ACTIVE_STATUS_ERROR";

//add article actions
export const ADD_ARTICLEL_SUCCESS = "ADD_ARTICLEL_SUCCESS";
export const ADD_ARTICLEL_ERROR = "ADD_ARTICLEL_ERROR";

//EDIT_ARTICLE Actions
export const EDIT_ARTICLEL_SUCCESS = "EDIT_ARTICLEL_SUCCESS";
export const EDIT_ARTICLEL_ERROR = "EDIT_ARTICLEL_ERROR";

//FETCH_ARTICLEL Actions
export const FETCH_ARTICLEL_INFO_SUCCESS = "FETCH_ARTICLEL_INFO_SUCCESS";
export const FETCH_ARTICLEL_INFO_ERROR = "FETCH_ARTICLEL_INFO_ERROR";

//get reportedby user details 
export const GET_REPORTEDUSERSLEARNING_SUCCESS = "GET_REPORTEDUSERSLEARNING_SUCCESS"
export const GET_REPORTEDUSERSLEARNING_ERROR = "GET_REPORTEDUSERSLEARNING_ERROR"